import firebase from "firebase";

const firebaseConfig = {
  apiKey: "AIzaSyDEtPSuDPoQV0c4mtfZP688tsU2umRRx48",
  authDomain: "btay-project-1551779459124.firebaseapp.com",
  databaseURL: "https://btay-project-1551779459124.firebaseio.com",
  projectId: "btay-project-1551779459124",
  storageBucket: "btay-project-1551779459124.appspot.com",
  messagingSenderId: "962292489151",
  appId: "1:962292489151:web:17d710427624c4ac21df0a",
  measurementId: "G-PVLB0HJV58",
};

// if (!firebase.apps.length) {
const firebaseApp = firebase.initializeApp(firebaseConfig);
// }

const firebaseMessaging = firebaseApp.messaging();

export { firebase, firebaseMessaging };
