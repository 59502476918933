import React, { useEffect } from "react";
// import { firebase } from "../Firebase/FirebaseConfig";
import FirebaseFirestoreService from "../Firebase/FirebaseFirestoreService";

// const db = firebase.firestore();

const Search = () => {
  const fetchQuestionData = async () => {
    const queries = [];

    // if (categoryFilter) {
    //   queries.push({
    //     field: "category",
    //     condition: "==",
    //     value: categoryFilter,
    //   });
    // }

    // if (!user) {
    //   queries.push({
    //     field: "isPublished",
    //     condition: "==",
    //     value: true,
    //   });
    // }

    // const orderByField = "publishDate";
    // let orderByDirection;

    // if (orderBy) {
    //   switch (orderBy) {
    //     case "publishDateAsc":
    //       orderByDirection = "asc";
    //       break;
    //     case "publishDateDesc":
    //       orderByDirection = "desc";
    //       break;
    //     default:
    //       break;
    //   }
    // }

    let fetchedRecipes = [];

    try {
      const response = await FirebaseFirestoreService.readDocuments({
        collection: "qna",
        queries: queries,
        // orderByField: orderByField,
        // orderByDirection: orderByDirection,
        orderByField: null,
        orderByDirection: null,
      });
      const newRecipes = response.docs.map((recipeDoc) => {
        const id = recipeDoc.id;
        const data = recipeDoc.data();
        // data.publishDate = new Date(data.publishDate.seconds * 1000);

        return { ...data, id };
      });

      fetchedRecipes = [...newRecipes];
      console.log("fetchedRecipes", fetchedRecipes);
    } catch (error) {
      console.error(error.message);
      throw error;
    }
    return fetchedRecipes;
  };

  useEffect(() => {
    fetchQuestionData();
  }, []);

  return <div>Search</div>;
};

export default Search;
