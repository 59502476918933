import React from "react";
import InputForm from "./Components/InputForm";
// import UserDatabase from "./Components/UserDatabase";
import Search from "./Components/search/Search";
import ContactUsForm from "./Components/ContactUsForm";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";

const App = () => {
  return (
    <>
      <Router>
        <Routes>
          {/* <Route path="/" element={<InputForm />} /> */}
          <Route path="/" element={<Search />} />
          {/* <Route exact path="/" element={<UserDatabase />} /> */}
          {/* <Route exact path="/form" element={<ContactUsForm />} /> */}

          {/* <Route path={"/form"}>
            <ContactUsForm />
          </Route> */}
        </Routes>
      </Router>
    </>
  );
};
export default App;
